/* .header-section-1 {
  display: flex;
  justify-content: center;
} */
.tables {
  width: 100%;
  /* display: table;
  border-spacing: 10px 28px;
  position: relative; */
}

.tables-header-content {
  /* padding: 20px; */
}
table {
  border-collapse: separate;
  border-spacing: 0px 16px;
}

th {
  /* background-color: #7cd093;  how can this line affect BulkExcelUpload table background color to become green all of a sudden */
  color: white;
}

th,
td {
  /* width: 150px; */
  text-align: center;
  /* border: 1px solid black; */
  padding: 5px;
}
tr {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
