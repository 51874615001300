/* Styles for the sticky header */
.sticky-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  /* background-color: white; */
  width: 100%;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.15;
  }
  100% {
    opacity: 1;
  }
}
