/* Animations START */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.penguinFlash {
  transition: opacity 1s fadeInOut, left 1s fadeInOut;
  position: absolute;
}

.penguinFlash.visible {
  opacity: 1; /* Fade in */
}

.grid-container {
  position: relative;
  width: 100%;
  height: 150px; /* Adjust as necessary */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation: flash 3s infinite;
}

.pulse {
  animation: pulse 3s infinite;
}

.slide-in-right {
  animation: slideInRight 3s ease-in-out;
}

.bounce {
  animation: bounce 3s infinite;
}
.slide-in-left {
  animation: slideInLeft 3s ease-in-out;
}

.fade-in {
  animation: fadeIn 3s ease-in-out;
}

.loader {
  position: relative; /* Make sure loader-inner is positioned relative to this */
  width: 30px; /* Set width and height */
  height: 30px;
  margin: auto; /* Center it within its container */
  margin-top: 20px;
}

.loader-inner {
  position: absolute; /* Position numbers absolutely within loader */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center exactly */
}

.loader-numbers {
  font-size: 14px; /* Adjust font size as needed */
  color: #3498db; /* Text color */
}

.loader::before {
  content: ""; /* Required for proper rendering in some browsers */
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pulse-breathing {
  animation: pulse-breathing 2s infinite;
  height: 100px; /* Adjust height as needed */
  width: 100px; /* Adjust width as needed */
  margin: 5px; /* Adds some spacing between images */
}

@keyframes pulse-breathing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

img:nth-child(1) {
  animation-delay: 3s;
}

img:nth-child(2) {
  animation-delay: 3s;
}

img:nth-child(3) {
  animation-delay: 3s;
}

/* Animations END */

.table-container {
  display: flex;
  gap: 20px; /* Adjust gap between tables as needed */
}

.table-wrapper {
  position: relative;
  overflow-y: auto;
  height: 400px; /* Set the height as needed */
  border: 1px solid #ccc; /* Optional: Add border for clarity */
}

.sheet-line {
  position: sticky;
  top: 0;
  background: #fff; /* Optional: Add background color */
  z-index: 10;
  padding: 8px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  position: sticky;
  /* top: 40px; Height of the sheet-line */
  /* background: #f9f9f9; Optional: Add background color to header */
  z-index: 5;
}

.table th,
.table td {
  width: 200px; /* Set the column width as needed */
}
/* .table td { */
/* background-color: #fff; */
/* } */
