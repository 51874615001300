.container {
  height: 178px;
  width: 100%;
  border: 2px solid #95d9a8;
  border-radius: 15px;
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  padding: 2px 22px;
  background-color: #f5f7f9;
  position: relative;
}

.productInfo {
  display: flex;
  flex: 0.3;
  border-right: 1px solid rgba(0, 0, 0, 0.53);
  flex-direction: column;
  padding-top: 13px;
}

.productContent1 {
  display: flex;
  flex-direction: row;
  height: 60%;
}

.productContent1div2 {
  flex: 0.5;
}

.productContent2 {
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputBox {
  background-color: #fff;
  height: 44px;
  width: 249px;
  display: flex;
  margin-top: 10;
  flex-direction: row;
  border-radius: 10px;
  align-items: left;
  justify-content: left;
  padding: 5px;
}
.productBox {
  background-color: rgba(124, 208, 147, 0.6);
  width: 148px;
  height: 59px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 10px 10px 50px 20px rgba(0, 0, 0, 0.04);
}

.extraInfo {
  display: flex;
  flex: 0.2;
  border-right: 1px solid rgba(0, 0, 0, 0.53);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.extraInfoTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.extraInfoBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.extraInfoBox {
  height: 71px;
  width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 10px 10px 50px 20px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  margin-left: 10px;
}
.remarksBox {
  height: 85px;
  width: 140px;
  align-items: center;
  justify-content: center;
  background: rgba(102, 128, 255, 0.12);
  border-radius: 10px;
  padding: 8px;
}
.companyInfo {
  display: flex;
  flex: 0.3;
  border-right: 1px solid rgba(0, 0, 0, 0.53);
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.dateInfo {
  display: flex;
  flex: 0.2;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.dateBox {
  height: 36px;
  padding: auto;
  background: rgba(196, 196, 196, 0.5);
  box-shadow: 10px 10px 50px 20px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropContainer {
  background: #ffffff;
  border: 1px solid black;
  box-shadow: 10px 10px 50px 20px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  padding: 10px;
  /* box-sizing: border-box; */
  /* height: 46px; */
  /* align-items: center;
  justify-content: center; */
  /* width: 178px; */
  /* text-align: "center"; */
  /* padding-top: 8px; */
  /* padding-left: 2px; */
  /* margin-top: -24px; */
}
